const Download = () => {
  function getMobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }
    return "android";
  }

  const redirectTo = getMobileOS() == "ios" ? APP_LINK_APP_STORE : APP_LINK_GOOGLE_PLAY;
  const link = document.getElementById("link-download");

  if (!!link) link.href = redirectTo;

  window.location.replace(redirectTo);
  return <a id="link-download"></a>;
};

export default Download;
