import { useSearchParams } from "react-router-dom";
import Guard from "src/guard/Guard";
import { TChildren } from "src/interfaces/Children";
import { accessCodeQueryKey } from "../components/queryParams";
import { musicPagePath } from "../paths";
import { useProductGuardCtx } from "./ProductGuard.ctx";

interface Props {
  children: TChildren;
}

const AccessCodeGuard = ({ children }: Props) => {
  const { artistId, codeToUnlock, hasExtras, productId } = useProductGuardCtx();

  const [searchParams, setSearchParams] = useSearchParams();
  const accessCode = searchParams.get(accessCodeQueryKey);

  const accessCodeMatchesProduct = codeToUnlock === accessCode;

  const canAccess = hasExtras && accessCodeMatchesProduct;

  return (
    <Guard
      canAccess={canAccess}
      whenCannotAccess={{
        redirectTo: `${musicPagePath}/${artistId}/${productId}`
      }}
    >
      {children}
    </Guard>
  );
};

export default AccessCodeGuard;
