import { TChildren } from "src/interfaces/Children";
import Guard from "src/guard/Guard";
import { useParams } from "react-router-dom";
import { productSlug } from "../slugs";
import { IProduct, products } from "../products";
import { musicPagePath } from "../paths";

interface Props {
  children: TChildren;
}

const ProductGuard = ({ children }: Props) => {
  const { productId } = useParams<typeof productSlug>();

  const productIndex = products.findIndex((el) => el.productId === productId);
  const productItem: IProduct | undefined = products[productIndex];

  const canAccess = !!productItem;

  return (
    <Guard
      canAccess={canAccess}
      whenCannotAccess={{
        redirectTo: musicPagePath
      }}
    >
      {children}
    </Guard>
  );
};

export default ProductGuard;
