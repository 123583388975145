import { TChildren } from "src/interfaces/Children";
import { products } from "../products";
import Guard from "src/guard/Guard";
import { musicPagePath } from "../paths";
import { useProductGuardCtx } from "./ProductGuard.ctx";

interface Props {
  children: TChildren;
}

const ProductMatchArtistGuard = ({ children }: Props) => {
  const { artistId, productId } = useProductGuardCtx();

  const productMatchArtist = products.some((prod) => prod.productId === productId && prod.artistId === artistId);

  const canAccess = productMatchArtist;

  return (
    <Guard
      canAccess={canAccess}
      whenCannotAccess={{
        redirectTo: musicPagePath
      }}
    >
      {children}
    </Guard>
  );
};

export default ProductMatchArtistGuard;
