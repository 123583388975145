import { ImgSrc } from "src/interfaces/ImgSrc";
import { ArtistsId } from "./artists";

const productsId = ["kintsugi", "bring-the-attention-to-the-body"] as const;
export type ProductsId = (typeof productsId)[number];

export interface IProduct {
  artistId: ArtistsId;
  productId: ProductsId;
  type: string;
  images: {
    main: ImgSrc;
    miniatures: ImgSrc[];
  };
  title: string;
  description: string;
  additionalDescription: string;
  interactions: {
    buyProduct?: {
      url: string;
      shopImg: string;
    };
    extras: {
      codeToUnlock: string;
    };
  };
}

const kintsugi: IProduct = {
  artistId: "kepinski-i-kowalonek",
  productId: "kintsugi",
  title: `KĘPIŃSKI >|< KOWALONEK - "Kintsugi" Winyl with autograph`,
  description:
    "This exceptional duo has already gained some extensive stage experience on the Polish music scene. Both the guys tend to create very moving, mesmerising and electrifying songs that combine classic electronic indie mixed with synth-pop sounds, therefore resulting in eclectic and unique music. From month to month, the duo surprises us with some novelties by revealing their cards about each new production and by dragging us deeper and deeper into their super-creative world.",
  additionalDescription:
    "Miro Kępiński has been into composing film music for years, and he is also the composer of the score to Furioza - a Netflix global phenomenon, which happened to become one of the most frequently watched movies in 2022, on that streaming platform. Michał Kowalonek, on the other hand, is a professional composer, songwriter and, above all, the voice of the two most important rock bands in our country: Snowman and Myslovitz.",
  images: {
    main: `/images/music/artists/kepinski-i-kowalonek/products/kintsugi/main.jpg`,
    miniatures: [
      "/images/music/artists/kepinski-i-kowalonek/products/kintsugi/min-1.jpg",
      "/images/music/artists/kepinski-i-kowalonek/products/kintsugi/min-2.jpg",
      "/images/music/artists/kepinski-i-kowalonek/products/kintsugi/min-3.jpg",
      "/images/music/artists/kepinski-i-kowalonek/products/kintsugi/min-4.jpg",
      "/images/music/artists/kepinski-i-kowalonek/products/kintsugi/min-5.jpg",
      "/images/music/artists/kepinski-i-kowalonek/products/kintsugi/min-6.jpg"
    ]
  },
  type: "Vinyl + {NFT}",

  interactions: {
    buyProduct: {
      url: "https://www.sklepzmuzyka.pl/kintsugi_winyl",
      shopImg: "/images/music/shops/sklep-z-muzyka.svg"
    },
    extras: {
      codeToUnlock: "HM78TP0"
    }
  }
};

const bringTheAttentionToTheBody: IProduct = {
  artistId: "duss",
  productId: "bring-the-attention-to-the-body",
  title: "Duss - Bring The Attention To The Body (Edit) 2024",
  description:
    "This limited edition vinyl merges pulsating rhythms with advanced NFT/NFC technology, providing a unique experience. The Go2NFT solution introduces new, never-before-seen features.",
  additionalDescription: `This limited-edition vinyl release features the track "Bring the Attention to the Body" by DUSS. This track perfectly captures their signature sound, combining energetic beats with a mesmerizing melody. Use the promo code available in the app on Go2NFT Entertainment's dedicated website to unlock unique content that will enhance your musical experience.`,
  images: {
    main: `/images/music/artists/duss/products/bring-the-attention-to-the-body/main.jpg`,
    miniatures: [
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-1.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-2.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-3.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-4.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-5.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-6.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-7.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-8.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-9.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-10.jpg`,
      `/images/music/artists/duss/products/bring-the-attention-to-the-body/min-11.jpg`
    ]
  },
  type: "Vinyl + {NFT}",
  interactions: {
    extras: {
      codeToUnlock: "DKFJW32"
    }
  }
};

export const products: IProduct[] = [kintsugi, bringTheAttentionToTheBody];

export const findProductById = (productId: string | undefined) => {
  return products.find((p) => p.productId === productId);
};
export const findProductByArtistId = (artistId: string | undefined) => {
  return products.find((p) => p.artistId === artistId);
};
