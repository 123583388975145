import React from "react";

import { IChildren } from "@components/interfaces/IChildren";
import { useParams } from "react-router-dom";
import { artistSlug, productSlug } from "../slugs";
import { findProductById, IProduct, ProductsId } from "../products";
import { ArtistsId, getArtistById, IArtist } from "../artists";
import { extras, IExtra } from "../extras";

interface IGuardCtx {
  productId: ProductsId | undefined;
  artistId: ArtistsId | undefined;
  codeToUnlock: string | undefined;
  hasExtras: boolean;
  productById: IProduct | undefined;
  artistById: IArtist | undefined;
  extraContent: IExtra | undefined;
}

const ProductGuardCtx = React.createContext(null as any);

export const ProductGuardProvider = ({ children }: IChildren) => {
  const { productId } = useParams<typeof productSlug>();
  const { artistId } = useParams<typeof artistSlug>();

  const productById = findProductById(productId);
  const codeToUnlock = productById?.interactions?.extras?.codeToUnlock;

  const hasExtras = !!productById?.interactions.extras;

  const artistById = getArtistById(artistId);
  const extraContent = (extras as any)[productId as any] as IExtra | undefined;

  return (
    <ProductGuardCtx.Provider
      value={{ productId, artistId, codeToUnlock, hasExtras, productById, artistById, extraContent }}
    >
      {children}
    </ProductGuardCtx.Provider>
  );
};

export const useProductGuardCtx = (): IGuardCtx => React.useContext(ProductGuardCtx);
