import React, { useState } from "react";

import { IChildren } from "@components/interfaces/IChildren";

interface IGalleryCtx {
  id: number | null;
  changeId(newId: number): void;
  resetId(): void;
  isIdPicked: boolean;
}

const GalleryCtx = React.createContext(null as any);

export const GalleryCtxProvider = ({ children }: IChildren) => {
  const [id, setId] = useState<number | null>(null);

  const changeId = (newId: number) => setId(newId);
  const resetId = () => setId(null);

  const isIdPicked = id !== null;

  return <GalleryCtx.Provider value={{ id, changeId, resetId, isIdPicked }}>{children}</GalleryCtx.Provider>;
};

export const useGalleryCtx = (): IGalleryCtx => React.useContext(GalleryCtx);
