import { TChildren } from "src/interfaces/Children";
import { Navigate, To } from "react-router-dom";

interface Props {
  children: TChildren;
  canAccess: boolean;
  whenCannotAccess: {
    renderInstead?: TChildren;
    callback?(): void;
    redirectTo?: To;
  };
}

const Guard = ({ canAccess, children, whenCannotAccess }: Props) => {
  if (!canAccess) {
    const { callback, redirectTo, renderInstead } = whenCannotAccess;
    if (!!callback) callback();
    if (!!redirectTo) {
      return <Navigate to={redirectTo} />;
    }
    return <>{renderInstead}</>;
  }

  return <>{children}</>;
};

export default Guard;
